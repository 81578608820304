import axios from 'axios';

const instance = new axios.create({
  xsrfCookieName: 'CRAFT_CSRF_TOKEN',
  xsrfHeaderName: 'X-CSRF-Token',
})

instance.interceptors.request.use(function (config) {
  return !window.csrfTokenValue ? Promise.reject({
    success: false,
    error: 'X-CSRF-Token is not defined'
  }) : config;
}, function (error) {
  return Promise.reject(error);
});

instance.defaults.headers.post['X-CSRF-Token'] = window.csrfTokenValue
instance.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
instance.defaults.headers['X-HTTP_X_REQUESTED_WITH-With'] = 'XMLHttpRequest'

export default instance
