<template>
  <div class="app-list">
    <div
      class="close-menu-overlay"
      :class="{ open: menus.make || menus.model || menus.year || menus.body }"
      @click="closeMenu"
    ></div>

    <div v-show="showFilters" class="filters-row">
      <div class="filters-row-toggle pt-4">
        <a class="button button-secondary" @click="toggleFilters">
            <span>{{filtersToggleText}}</span>
            <svg enable-background="new 0 0 15.6 28.3" viewBox="0 0 15.6 28.3" xmlns="http://www.w3.org/2000/svg" fill=""><path d="m0 0h15.5c-.9 11.3-5.7 20.2-9.6 24-.5.5-2.4 2.1-2.4 2.1-2 1.5-3.5 2.2-3.5 2.2z"/></svg>
        </a>
      </div>
      <div class="row" v-if="filtersVisible" :class="{ 'mobile' : filtersVisibleMobile }">
        <div class="col-md-6 col-lg-3 col-xl-4">
          <div class="input-wrap is-underlined w-icon">
            <svg
              enable-background="new 0 0 20 21"
              viewBox="0 0 20 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m20 18.6-5.4-5.4c1-1.4 1.7-3.1 1.7-4.9 0-4.5-3.6-8.1-8.1-8.1s-8.2 3.6-8.2 8.1 3.6 8.1 8.1 8.1c1.5 0 2.8-.4 4-1.1l5.6 5.6zm-16.8-10.3c0-2.7 2.2-4.9 4.9-4.9s4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9-4.9-2.3-4.9-4.9z"
              />
            </svg>
            <input
              type="text"
              v-model="appliedFilters.search"
              class="input"
              id="search-app"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-8 offset-lg-1 col-xl-7 offset-lg-1">
          <div
            class="d-lg-flex align-items-center select-drop-wrap"
            :class="{
              open: menus.make || menus.body || menus.model || menus.year,
            }"
          >
            <div
              v-if="hasFilters"
              class="caption pr-3 has-pointer text-red"
              @click="clearFilters"
            >
              <span>Clear</span>
            </div>
            <div v-else class="caption pr-3"><span>Filters</span></div>
            <div class="is-relative">
              <div class="input-line">
                <div
                  class="input-wrap select is-underlined"
                  :class="{ active: menus.make }"
                  @click="toggleMenu('make')"
                >
                  <div class="input">Make</div>
                  <div class="selected-filter">{{ makeText }}</div>
                </div>
                <div
                  class="input-wrap select is-underlined"
                  :class="{
                    active: menus.model,
                    disabled: appliedFilters.make === null,
                  }"
                  @click="toggleMenu('model')"
                >
                  <div class="input">Model</div>
                  <div class="selected-filter">{{ modelText }}</div>
                </div>
                <div
                  class="input-wrap select is-underlined"
                  :class="{ active: menus.body }"
                  @click="toggleMenu('body')"
                >
                  <div class="input">Body Style</div>
                  <div class="selected-filter">{{ bodyStyleText }}</div>
                </div>
                <div
                  class="input-wrap select is-underlined"
                  :class="{ active: menus.year }"
                  @click="toggleMenu('year')"
                >
                  <div class="input">Year</div>
                  <div class="selected-filter">{{ yearText }}</div>
                </div>
              </div>
              <div class="select-drop">
                <div class="select-drop-item" :class="{ open: menus.make }">
                  <div class="radio-rounded" ref="input">
                    <label v-for="m in makeOptions" :key="m.make.id">
                      <input
                        class="input-radio"
                        type="radio"
                        name="make"
                        :disabled="m.disabled"
                        :value="m.make.id"
                        v-model="appliedFilters.make"
                      />
                      <div
                        class="radio-click"
                        :class="{ disabled: m.disabled }"
                      >
                        {{ m.make.title }}
                      </div>
                    </label>
                  </div>
                </div>
                <div class="select-drop-item" :class="{ open: menus.model }">
                  <div class="checkbox-group">
                    <label
                      v-for="m in modelOptions"
                      :key="m.model.id"
                      class="checkbox"
                    >
                      <input
                        type="checkbox"
                        name="model"
                        v-model="appliedFilters.model"
                        :value="m.model.id"
                      />
                      <div class="has-checkbox">
                        <span>{{ m.model.title }}</span>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="select-drop-item" :class="{ open: menus.year }">
                  <div class="input-line">
                    <div class="input-wrap select">
                      <label class="input-label" for="year-from">From</label>
                      <select
                        class="input"
                        v-model="appliedFilters.fromYear"
                        id="year-from"
                      >
                        <option
                          v-for="year in fromYearOptions"
                          :value="year.value"
                          :key="year.label"
                        >
                          {{ year.label }}
                        </option>
                      </select>
                    </div>
                    <div class="input-wrap select">
                      <label class="input-label" for="year-to">To</label>
                      <select
                        class="input"
                        v-model="appliedFilters.toYear"
                        id="year-to"
                      >
                        <option
                          v-for="year in toYearOptions"
                          :value="year.value"
                          :key="year.label"
                        >
                          {{ year.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="select-drop-item" :class="{ open: menus.body }">
                  <div class="radio-rounded" ref="input">
                    <label
                      v-for="bS in bodyStyleOptions"
                      :key="bS.bodyStyle.id"
                    >
                      <input
                        class="input-radio"
                        :disabled="bS.disabled"
                        type="radio"
                        :id="bS.bodyStyle.id"
                        name="bodyStyle"
                        :value="bS.bodyStyle.id"
                        v-model="appliedFilters.bodyStyle"
                      />
                      <div
                        class="radio-click"
                        :class="{ disabled: bS.disabled }"
                      >
                        {{ bS.bodyStyle.title }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="t-sm text-gray2">{{ qty }}</div>
      </div>
      <div class="col-6">
        <div class="sort-wrap">
          <div class="caption pr-3">Sort</div>
          <div class="small-select">
            <select v-model="appliedFilters.sortBy">
              <option
                v-for="option in sortByOptions"
                :value="option.value"
                :key="option.label"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <virtual-list
      data-key="id"
      :data-sources="filteredVehicles"
      :data-component="itemComponent"
      :page-mode="true"
      :keeps="3"
    />
  </div>
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import ListItem from "./ListItem.vue";
import { sortBy, uniqBy, range, chunk } from "lodash";

const initialAppliedFilters = {
  make: null,
  bodyStyle: null,
  model: [],
  search: "",
  fromYear: null,
  toYear: null,
  sortBy: "newest",
};
const addAllOption = (arr) => {
  return [
    {
      disabled: false,
      bodyStyle: { id: null, title: "All" },
      make: { id: null, title: "All" },
    },
    ...arr,
  ];
};

const addAnyOption = (arr) => {
  return [
    {
      label: "Any",
      value: null,
    },
    ...arr,
  ];
};
export default {
  name: "AppList",
  components: { VirtualList, ListItem },
  data() {
    return {
      itemComponent: ListItem,
      appliedFilters: {
        ...initialAppliedFilters,
      },
      menus: {
        make: false,
        model: false,
        year: false,
        body: false,
      },
      sortByOptions: [
        {
          label: "Newest",
          value: "newest",
        },
        {
          label: "Price (low to high)",
          value: "priceLowToHigh",
        },
        {
          label: "Price (high to low)",
          value: "priceHighToLow",
        },
        {
          label: "Year",
          value: "year",
        },
        {
          label: "Make",
          value: "make",
        },
      ],
      lsBase: location.pathname.replace("/", "").replace(/\//g, "_") + "_",
      filtersVisible: true,
      mobileFitersChecked: false,
      filtersVisibleMobile: false,
      filtersToggleText: 'Search Filters',
    };
  },
  watch: {
    "appliedFilters.make": {
      handler(val) {
        if (val === null) {
          this.appliedFilters.model = [];
        }
      },
    },
    appliedFilters: {
      handler(val) {
        if (
          !this.fromYearOptions.map((obj) => obj.value).includes(val.fromYear)
        ) {
          this.appliedFilters.fromYear = this.fromYearOptions[0].value;
        }
        if (!this.toYearOptions.map((obj) => obj.value).includes(val.toYear)) {
          this.appliedFilters.toYear = this.toYearOptions[0].value;
        }

        if (val.fromYear > val.toYear) {
          this.appliedFilters.toYear = null;
        }

        let parts = "";

        if (val.make !== null) {
          const makeTitle = this.allVehicles.find(
            (veh) => veh.make.id === val.make
          ).make.title;
          parts += "/make:" + makeTitle.replace(" ", "-").toLowerCase();
        }

        if (val.model.length) {
          let modelTitle = [];
          val.model.forEach((val) => {
            modelTitle.push(
              this.allVehicles
                .find((veh) => veh.model.id === val)
                .model.title.replace(" ", "-")
                .toLowerCase()
            );
          });
          parts += "/model:" + modelTitle.join(",");
        }

        if (val.fromYear !== null) {
          parts += "/fromYear:" + val.fromYear;
        }

        if (val.toYear !== null) {
          parts += "/toYear:" + val.toYear;
        }

        if (val.bodyStyle !== null) {
          const bodyStyleTitle = this.allVehicles.find(
            (veh) => veh.bodyStyle.id === val.bodyStyle
          ).bodyStyle.title;
          parts +=
            "/bodyStyle:" + bodyStyleTitle.replace(" ", "-").toLowerCase();
        }

        if (val.sortBy !== null) {
          parts += "/sortBy:" + val.sortBy;
        }

        if (val.search.length) {
          parts += "/search:" + val.search;
        }

        if (!window.location.hash && parts !== '/sortBy:newest' || window.location.hash) {
          history.pushState({}, "", `#${parts}`);
        }

        if (parts === '/sortBy:newest') {
          window.location.hash = ''
        }

        localStorage.setItem(
          this.lsBase + "appliedFilters",
          JSON.stringify({
            timestamp: new Date().getTime(),
            value: val,
          })
        );

        /* close the modal on selection - but if we've got a to/from year make sure both are set before closing */
        if( (val.fromYear !== null && val.toYear !== null) || val.fromYear === null && val.toYear === null )
        {
          this.closeMenu();
        }

      },

      deep: true,
    },
    allVehicles: {
      handler(vehicles) {
        this.loadFilterValuesFromHashOrLocalStorage(vehicles);
      },
    },
    modelOptions: {
      handler(val) {
        this.appliedFilters.model = this.appliedFilters.model.filter((v) =>
          val.map((obj) => obj.model.id).includes(v)
        );
      },
    },
  },
  computed: {
    allVehicles() {
      let allVehicles = this.$root.$data.vehicles;

      if (
        this.isPerformancePage ||
        this.stockNos.length ||
        this.isSaleOnly ||
        this.isHot ||
        this.onSaleOrIsHot ||
        this.specificDealer > 0
      ) {

        let count = 0;
        allVehicles = allVehicles.filter((v) => {

            const isHot = this.isHot
              ? v['isHot'] === true
              : true;
            const perf = this.isPerformancePage
              ? v["carIsPerformance"] === true
              : true;
            const nos = this.stockNos.length
              ? this.stockNos.indexOf(v["stockNo"]) > -1
              : true;
            const sale = this.isSaleOnly ? v['carIsOnSale'] === true : true;
            const dealer =
              this.specificDealer > 0
                ? v["dealer"].id === this.specificDealer
                : true;
            if(this.onSaleOrIsHot === true)
            {

                // check everything apart from isHot or carIsOnSale - as we know they are true.
                // this way the performance and dealership filters are still applied.
                if( (v['isHot'] === true || v['carIsOnSale'] === true)){
                    return  perf && nos && dealer;
                } else {
                  return false;
                }

            }
            else {
              //we're checking everything.
              return isHot && perf && nos && sale && dealer;
            }
            
        });
      }

      return allVehicles;
    },
    qty() {
      const qty = this.filteredVehicles.reduce(
        (acc, cv) => acc + cv.row.length,
        0
      );
      return qty > 1 ? qty + " vehicles" : qty + " vehicle";
    },
    showFilters() {
      return this.$root.$data.showFilters !== "false";
    },
    isPerformancePage() {
      return this.$root.$data.performance !== "false";
    },
    isSaleOnly() {
      return this.$root.$data.saleOnly !== "false";
    },
    specificDealer() {
      return this.$root.$data.specificDealer;
    },
    stockNos() {
      return this.$root.$data.stockNos;
    },
    isHot() {
      return this.$root.$data.isHot !== "false";
    },
    onSaleOrIsHot() {
      return this.$root.$data.onSaleOrIsHot !== "false";
    },
    filteredVehicles() {
      let allVehicles2 = this.allVehicles;

      if (this.appliedFilters.make !== null) {
        allVehicles2 = allVehicles2.filter(
          (v) => v.make.id === this.appliedFilters.make
        );
      }
      if (!!this.appliedFilters.model.length) {
        allVehicles2 = allVehicles2.filter((v) =>
          this.appliedFilters.model.includes(v.model.id)
        );
      }
      if (this.appliedFilters.bodyStyle !== null) {
        allVehicles2 = allVehicles2.filter(
          (v) => v.bodyStyle.id === this.appliedFilters.bodyStyle
        );
      }
      if (!!this.appliedFilters.search.length) {
        allVehicles2 = allVehicles2.filter((v) =>
          v.title
            .toLowerCase()
            .includes(this.appliedFilters.search.toLowerCase())
        );
      }

      if (this.appliedFilters.fromYear !== null) {
        allVehicles2 = allVehicles2.filter(
          (v) => v.year >= this.appliedFilters.fromYear
        );
      }

      if (this.appliedFilters.toYear !== null) {
        allVehicles2 = allVehicles2.filter(
          (v) => v.year <= this.appliedFilters.toYear
        );
      }

      switch (this.appliedFilters.sortBy) {
        case "newest": {
          allVehicles2 = sortBy(allVehicles2, "order");
          break;
        }
        case "priceLowToHigh": {
          allVehicles2 = sortBy(allVehicles2, "price");
          break;
        }
        case "priceHighToLow": {
          allVehicles2 = sortBy(allVehicles2, "price").reverse();
          break;
        }
        case "year": {
          allVehicles2 = sortBy(allVehicles2, "year").reverse();
          break;
        }
        case "make": {
          allVehicles2 = sortBy(allVehicles2, (e) => e.make.title);
          break;
        }
      }
      return chunk(allVehicles2, 6).map((c) => {
        return { id: c[0].id, row: c };
      });
    },
    makeOptions() {
      let arr = this.allVehicles;

      if (!!this.appliedFilters.model.length) {
        arr = arr.filter((v) => this.appliedFilters.model.includes(v.model.id));
      }

      if (this.appliedFilters.bodyStyle !== null) {
        arr = arr.filter(
          (v) => v.bodyStyle.id === this.appliedFilters.bodyStyle
        );
      }

      if (this.appliedFilters.fromYear !== null) {
        arr = arr.filter((v) => v.year >= this.appliedFilters.fromYear);
      }

      if (this.appliedFilters.toYear !== null) {
        arr = arr.filter((v) => v.year <= this.appliedFilters.toYear);
      }

      if (!!this.appliedFilters.search.length) {
        arr = arr.filter((v) =>
          v.title
            .toLowerCase()
            .includes(this.appliedFilters.search.toLowerCase())
        );
      }

      const allUniqueMake = uniqBy(this.allVehicles, (e) => e.make.id);

      const uniqueMake = uniqBy(arr, (e) => e.make.id);

      const newArr = allUniqueMake.map((val) => {
        if (!!uniqueMake.find((obj) => obj.make.id === val.make.id)) {
          return {
            disabled: false,
            make: val.make,
          };
        } else {
          return {
            disabled: true,
            make: val.make,
          };
        }
      });
      return addAllOption(sortBy(newArr, (e) => e.make.title));
    },
    modelOptions() {
      let arr = this.allVehicles;

      if (!!this.appliedFilters.make !== null) {
        arr = arr.filter((v) => v.make.id === this.appliedFilters.make);
      }

      if (this.appliedFilters.bodyStyle !== null) {
        arr = arr.filter(
          (v) => v.bodyStyle.id === this.appliedFilters.bodyStyle
        );
      }

      if (this.appliedFilters.fromYear !== null) {
        arr = arr.filter((v) => v.year >= this.appliedFilters.fromYear);
      }

      if (this.appliedFilters.toYear !== null) {
        arr = arr.filter((v) => v.year <= this.appliedFilters.toYear);
      }

      if (!!this.appliedFilters.search.length) {
        arr = arr.filter((v) =>
          v.title
            .toLowerCase()
            .includes(this.appliedFilters.search.toLowerCase())
        );
      }

      const allUniqueModel = uniqBy(
        this.allVehicles.filter((v) => v.make.id === this.appliedFilters.make),
        (e) => e.model.id
      );

      const uniqueModel = uniqBy(arr, (e) => e.model.id);

      const newArr = allUniqueModel.filter((val) => {
        return !!uniqueModel.find((obj) => obj.model.id === val.model.id);
      });
      return sortBy(newArr, (e) => e.model.title);
    },
    bodyStyleOptions() {
      let arr = this.allVehicles;

      if (!!this.appliedFilters.model.length) {
        arr = arr.filter((v) => this.appliedFilters.model.includes(v.model.id));
      }

      if (this.appliedFilters.make !== null) {
        arr = arr.filter((v) => v.make.id === this.appliedFilters.make);
      }

      if (this.appliedFilters.fromYear !== null) {
        arr = arr.filter((v) => v.year >= this.appliedFilters.fromYear);
      }

      if (this.appliedFilters.toYear !== null) {
        arr = arr.filter((v) => v.year <= this.appliedFilters.toYear);
      }

      if (!!this.appliedFilters.search.length) {
        arr = arr.filter((v) =>
          v.title
            .toLowerCase()
            .includes(this.appliedFilters.search.toLowerCase())
        );
      }

      const allUniqueBodyStyle = uniqBy(
        this.allVehicles.filter(e => e.bodyStyle && e.bodyStyle.id),
        e => e.bodyStyle.id
      );

      const uniqueBodyStyle = uniqBy(
        arr.filter(e => e.bodyStyle && e.bodyStyle.id),
        e => e.bodyStyle.id
      );

      const newArr = allUniqueBodyStyle.map((val) => {
        if (
          !!uniqueBodyStyle.find((obj) => obj.bodyStyle.id === val.bodyStyle.id)
        ) {
          return {
            disabled: false,
            bodyStyle: val.bodyStyle,
          };
        } else {
          return {
            disabled: true,
            bodyStyle: val.bodyStyle,
          };
        }
      });
      return addAllOption(sortBy(newArr, (e) => e.bodyStyle.title));
    },
    fromYearOptions() {
      let arr = this.allVehicles;

      if (this.appliedFilters.make !== null) {
        arr = arr.filter((obj) => obj.make.id === this.appliedFilters.make);
      }

      if (!!this.appliedFilters.model.length) {
        arr = arr.filter((obj) =>
          this.appliedFilters.model.includes(obj.model.id)
        );
      }

      if (this.appliedFilters.bodyStyle !== null) {
        arr = arr.filter(
          (obj) => obj.bodyStyle.id === this.appliedFilters.bodyStyle
        );
      }

      if (!!this.appliedFilters.search.length) {
        arr = arr.filter((v) =>
          v.title
            .toLowerCase()
            .includes(this.appliedFilters.search.toLowerCase())
        );
      }
      if (!arr.length) {
        return addAnyOption([]);
      }
      const sortedByYears = sortBy(arr, "year");
      const fromYears = range(
        sortedByYears[0].year,
        sortedByYears[sortedByYears.length - 1].year + 1
      ).map((year) => ({ value: year, label: year }));

      return addAnyOption(fromYears);
    },
    toYearOptions() {
      let arr = this.allVehicles;

      if (this.appliedFilters.make !== null) {
        arr = arr.filter((obj) => obj.make.id === this.appliedFilters.make);
      }

      if (!!this.appliedFilters.model.length) {
        arr = arr.filter((obj) =>
          this.appliedFilters.model.includes(obj.model.id)
        );
      }

      if (this.appliedFilters.bodyStyle !== null) {
        arr = arr.filter(
          (obj) => obj.bodyStyle.id === this.appliedFilters.bodyStyle
        );
      }

      if (!!this.appliedFilters.search.length) {
        arr = arr.filter((v) =>
          v.title
            .toLowerCase()
            .includes(this.appliedFilters.search.toLowerCase())
        );
      }
      if (!arr.length) {
        return addAnyOption([]);
      }
      const sortedByYears = sortBy(arr, "year");
      const toYears = range(
        this.appliedFilters.fromYear !== null
          ? this.appliedFilters.fromYear
          : sortedByYears[0].year,
        sortedByYears[sortedByYears.length - 1].year + 1
      ).map((year) => ({ value: year, label: year }));

      return addAnyOption(toYears);
    },
    hasFilters() {
      let test = (this.appliedFilters.make !== null ||
        this.appliedFilters.bodyStyle !== null ||
        this.appliedFilters.model.length ||
        this.appliedFilters.fromYear !== null ||
        this.appliedFilters.toYear !== null);
      if(test && this.filtersVisibleMobile === false && this.mobileFitersChecked === false) {
        this.toggleFilters()
        this.mobileFitersChecked = true;
      }
      return test;

      
    },
    makeText() {
      const make = this.makeOptions.length
        ? this.makeOptions.find((m) => m.make.id === this.appliedFilters.make)
        : null;
      return make ? make.make.title : "";
    },
    bodyStyleText() {
      const bodyStyle = this.bodyStyleOptions.length
        ? this.bodyStyleOptions.find(
            (m) => m.bodyStyle.id === this.appliedFilters.bodyStyle
          )
        : null;
      return bodyStyle ? bodyStyle.bodyStyle.title : "";
    },
    modelText() {
      const models = this.appliedFilters.model.reduce((mods, mod) => {
        const model = this.modelOptions.find((m) => m.model.id === mod);
        if (model && mods.length < 3) {
          mods.push(model.model.title);
        }
        return mods;
      }, []);
      return models.join(", ") + (models.length === 3 ? " ..." : "");
    },
    yearText() {
      const from =
        this.appliedFilters.fromYear !== null
          ? this.appliedFilters.fromYear
          : null;
      const to =
        this.appliedFilters.toYear !== null ? this.appliedFilters.toYear : null;
      return from && to ? from + " - " + to : from ? from : to ? to : "";
    },
  },
  methods: {
    toggleFilters() {
      this.filtersVisibleMobile = !this.filtersVisibleMobile;
      this.filtersToggleText = this.filtersVisibleMobile ? 'Hide Filters' : 'Search Filters';
    },
    clearFilters() {
      this.closeMenu();
      this.appliedFilters = {
        ...this.appliedFilters,
        make: null,
        bodyStyle: null,
        model: [],
        fromYear: null,
        toYear: null,
      };
    },
    closeMenu() {
      this.menus.make = false;
      this.menus.model = false;
      this.menus.year = false;
      this.menus.body = false;
    },
    toggleMenu(menu) {
      if (menu !== "make") {
        this.menus.make = false;
      }
      if (menu !== "model") {
        this.menus.model = false;
      }
      if (menu !== "year") {
        this.menus.year = false;
      }
      if (menu !== "body") {
        this.menus.body = false;
      }

      this.menus[menu] = !this.menus[menu];
    },
    loadFilterValuesFromHashOrLocalStorage(vehicles) {
      const decodeFilterValuesFromHash = (hash) => {
        const appliedFilters = {
          ...initialAppliedFilters,
        };

        hash.forEach((element) => {
          let parts = element.split(":");
          switch (parts[0]) {
            case "model":
              {
                const modelIds = [];
                parts[1].split(",").forEach((val) => {
                  vehicles.find((veh) => {
                    return (
                      veh.model.title.toLowerCase() ===
                      val.replace("-", " ").toLowerCase()
                    );
                  })
                    ? modelIds.push(
                        vehicles.find(
                          (veh) =>
                            veh.model.title.toLowerCase() ===
                            val.replace("-", " ").toLowerCase()
                        ).model.id
                      )
                    : null;
                });
                appliedFilters.model = modelIds;
              }
              break;
            case "fromYear":
              appliedFilters.fromYear = Number(parts[1]);
              break;
            case "toYear":
              appliedFilters.toYear = Number(parts[1]);
              break;
            case "bodyStyle":
              {
                appliedFilters.bodyStyle = vehicles.find(
                  (veh) =>
                    veh.bodyStyle.title.toLowerCase() ===
                    parts[1].replace("-", " ").toLowerCase()
                ).bodyStyle.id;
              }
              break;
            case "make":
              {
                appliedFilters.make = vehicles.find(
                  (veh) =>
                    veh.make.title.toLowerCase() ===
                    parts[1].replace("-", " ").toLowerCase()
                ).make.id;
              }
              break;
            case "sortBy":
              appliedFilters.sortBy = parts[1];
              break;
            case "search":
              appliedFilters.search = decodeURIComponent(parts[1]);
              break;
          }
        });
        return appliedFilters;
      };
      if (this.$root.$data.search) {
        this.appliedFilters.search = this.$root.$data.search;
      }
      this.appliedFilters.sortBy = this.$root.$data.defaultSortOrder;
      const hash = window.location.hash;
      if (hash.length) {
        const hash = window.location.hash.replace("#/", "").split("/");
        this.appliedFilters = decodeFilterValuesFromHash(hash);
      } else {
        const storedFilters = localStorage.getItem(this.lsBase + "appliedFilters")
          ? JSON.parse(localStorage.getItem(this.lsBase + "appliedFilters"))
          : null;

        // check if last timestamp is older than 2 hours
        if (storedFilters && ((storedFilters.timestamp + 7200000) > new Date().getTime())) {
          this.appliedFilters = storedFilters.value;
        }
      }
    },
  },
  mounted() {
    if (this.allVehicles.length) {
      this.loadFilterValuesFromHashOrLocalStorage(this.allVehicles);
    }
  },
};
</script>
