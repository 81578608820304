<template>
  <div v-if="resp">
    <div
      class="calc-row calc-amounts"
      :class="was ? 'mb-0' : ''"
      v-if="notModal"
    >
      <div class="calc-col">
        <div class="t-sm-2 is-upper is-bold">Vehicle Price</div>
      </div>
      <div class="calc-col">
        <div v-if="price" class="price is-bold">{{ amount | money }}</div>
        <div v-else class="input-wrap currency-input">
          <input
            type="text"
            v-model="amountFormatted"
            class="input"
            placeholder="Enter Loan amount"
            ref="input"
            @change="getData(true)"
            @keyup="getData(true)"
          />
        </div>
      </div>
    </div>
    <div class="calc-row calc-was" v-if="was">
      <div class="calc-col"></div>
      <div class="calc-col">
        <div class="vehicle-tile-sale">was ${{ was | money }}</div>
      </div>
    </div>

    <div class="calc-row calc-payment" v-if="price && notModal">
      <div class="calc-col">
        <div class="t-l-1 text-red is-bold text-uppercase">
          Indicative payment
        </div>
      </div>
      <div class="calc-col">
        <div class="text-red">
          <i
            ><span class="price is-bold">{{
              resp[frequency]["amount"] | money
            }}</span>
            {{ frequency }}*</i
          >
        </div>
      </div>
    </div>
    <div class="calc-row">
      <div class="calc-col">
        <div class="t-sm-2 is-upper">Loan term</div>
      </div>
      <div class="calc-col">
        <vue-slider
          v-model="term"
          :max="5"
          :min="1"
          tooltip="always"
          tooltip-placement="bottom"
          :dotSize="20"
          :contained="true"
          :tooltipFormatter="yearFormat"
          :adsorb="true"
          :lazy="true"
          @change="getData"
        />
      </div>
    </div>
    <div class="calc-row">
      <div class="calc-col">
        <div class="t-sm-2 is-upper">Deposit</div>
      </div>
      <div class="calc-col">
        <vue-slider
          v-model="deposit"
          :max="max"
          :min="0"
          :interval="50"
          tooltip="always"
          tooltip-placement="bottom"
          :dotSize="20"
          :contained="true"
          :tooltipFormatter="moneyFormat"
          :adsorb="true"
          :lazy="true"
          @change="getData"
        />
      </div>
    </div>
    <div class="calc-row">
      <div class="calc-col">
        <div class="t-sm-2 is-upper">Pay</div>
      </div>
      <div class="calc-col calc-pay-radios">
        <div class="radio-rounded">
          <label>
            <input
              type="radio"
              name="pay"
              value="weekly"
              v-model="frequency"
              class="input-radio"
              @change="updateFinanceModal"
            />
            <span class="radio-click">Weekly</span>
          </label>
          <label>
            <input
              type="radio"
              name="pay"
              value="fortnightly"
              v-model="frequency"
              class="input-radio"
              @change="updateFinanceModal"
            />
            <span class="radio-click">Fortnightly</span>
          </label>
          <label>
            <input
              type="radio"
              name="pay"
              value="monthly"
              v-model="frequency"
              class="input-radio"
              @change="updateFinanceModal"
            />
            <span class="radio-click">Monthly</span>
          </label>
        </div>
      </div>
    </div>
    <div
      class="calc-row big-price"
      v-if="!price"
      :class="{ 'not-ready': youPay < 1 }"
    >
      <div class="calc-col">
        <div class="text-red is-bold text-uppercase">Indicative payment</div>
      </div>
      <div class="calc-col">
        <div class="text-red">
          <i
            ><span class="price is-bold">{{ youPay }}</span> {{ frequency }}*</i
          >
        </div>
      </div>
    </div>
    <div class="calc-bottom">
      <div
        class="button"
        @click="openModal"
        :class="{ disabled: youPay < 1 }"
        v-if="notModal"
      >
        <span
          >Apply for <i><b>Super Finance</b></i></span
        >
        <svg
          enable-background="new 0 0 15.6 28.3"
          viewBox="0 0 15.6 28.3"
          xmlns="http://www.w3.org/2000/svg"
          fill=""
        >
          <path
            d="m0 0h15.5c-.9 11.3-5.7 20.2-9.6 24-.5.5-2.4 2.1-2.4 2.1-2 1.5-3.5 2.2-3.5 2.2z"
          ></path>
        </svg>
      </div>
      <div
        class="t-sm-2 mt-4 calc-small wysiwyg"
        v-html="smallText"
        :class="{ 'is-invisible': youPay < 1 }"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "../helpers/axios";
import VueSlider from "vue-slider-component";
import select from "dom-select";
import { EventBus, formModalOpenGTMEventObject } from "../app";
export default {
  name: "Calculator",
  components: { VueSlider },
  props: ["price", "was", "isModal"],
  data() {
    return {
      resp: null,
      notModal: false,
      afterInput: false,
      frequency: "weekly",
      deposit: -1,
      amount: 0,
      amountFormatted: "",
      term: null,
      max: 50,
      vehicleInfo: {
        price: null,
        frequency: null,
      },
      updateFunctionNameOn: null,
      updateFunctionNameCall: null,
      financeModal: {
        tileFinancePrice: null,
        tileFinanceFreq: null,
        grayText: null,
        calcTerm: null,
        calcDeposit: null,
        calcFrequency: null,
        calcAmountPerFrequency: null,
        calcFinanceAmount: null,
      },
    };
  },
  computed: {
    smallText() {
      if (this.resp && this.frequency) {
        return this.resp[this.frequency]["financeDesc"];
      } else {
        return "";
      }
    },
    youPay() {
      return this.resp &&
        this.frequency &&
        this.resp[this.frequency]["amount"] > 0 &&
        this.afterInput
        ? this.resp[this.frequency]["amount"]
        : 0;
    },
  },
  watch: {
    amountFormatted(v) {
      const result = v.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => {
        this.amount = Number(v.replace(",", ""));
        this.amountFormatted = result;
      });
    },
  },
  methods: {
    setMax() {
      const money = this.amount ? this.amount : 2000;
      const max = Math.ceil(money / 50) * 50 - 1000;
      this.max = max > 0 && max > this.deposit ? max : this.deposit + 50;
    },
    getData(afterInput) {
      this.setMax();

      if (afterInput && this.amount < 1000) {
        this.deposit = 0;
        this.price = 0;
        this.resp[this.frequency]["amount"] = 0;
        this.updateFinanceModal();
        return false;
      }

      if (this.amount < 1) {
        this.deposit = 0;
        this.afterInput = false;
      }

      if (afterInput && this.amount <= this.deposit) {
        this.deposit = this.amount / 2;
      }

      return axios(
        `/api/finance-calc.json?deposit=${this.deposit}&amount=${
          this.amount ? this.amount : 0
        }&term=${!this.term ? -1 : this.term * 12}`
      ).then(({ data }) => {
        this.resp = data;
        this.term = data.term / 12;
        this.deposit = Number(data.deposit) > 0 ? Number(data.deposit) : 0;
        this.updateFinanceModal();

        if (afterInput && !this.afterInput && this.amount) {
          this.afterInput = true;
        }
      });
    },
    yearFormat: (val) => (val > 1 ? val + " years" : val + " year"),
    moneyFormat: (val) =>
      "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    openModal() {
      const target = window.modals
        ? window.modals.find((m) => m.name === "finance")
        : null;
      if (target) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(formModalOpenGTMEventObject(document, "finance"));
        target.open();
      }
    },
    money(val) {
      return this.$options.filters.money(val);
    },
    updateFinanceModal() {
      if (this.afterInput) {
        var eventCall = "updatePage";
        if (!this.isModal) {
          eventCall = "updatePageModal";
        }
        EventBus.$emit(eventCall, {
          data: this.resp,
          frequency: this.frequency,
        });
      }

      //if (this.financeModal.tileFinancePrice) { this.financeModal.tileFinancePrice.innerHTML = this.money(this.resp[this.frequency]['amount']) }
      if (this.financeModal.tileFinancePrice) {
        this.financeModal.tileFinancePrice.forEach((input) => {
          //console.log(this,this.term);
          input.innerHTML = this.money(this.resp[this.frequency]["amount"]);
        });
      }

      //if (this.financeModal.tileFinanceFreq) { this.financeModal.tileFinanceFreq.innerHTML = this.frequency+'*' }
      if (this.financeModal.tileFinanceFreq) {
        this.financeModal.tileFinanceFreq.forEach((input) => {
          //console.log(this,this.term);
          input.innerHTML = this.frequency + "*";
        });
      }

      //if (this.financeModal.grayText) { this.financeModal.grayText.innerHTML = this.smallText }
      if (this.financeModal.grayText) {
        this.financeModal.grayText.forEach((input) => {
          //console.log(this,this.term);
          input.innerHTML = this.smallText;
        });
      }

      if (this.financeModal.calcTerm) {
        this.financeModal.calcTerm.forEach((input) => {
          //console.log(this,this.term);
          input.value = this.term;
        });
      }
      if (this.financeModal.calcDeposit) {
        this.financeModal.calcDeposit.forEach((input) => {
          input.value = this.money(this.resp["deposit"]);
        });
      }
      if (this.financeModal.calcFrequency) {
        this.financeModal.calcFrequency.forEach((input) => {
          input.value = this.frequency;
        });
      }
      if (this.financeModal.calcAmountPerFrequency) {
        this.financeModal.calcAmountPerFrequency.forEach((input) => {
          input.value = this.money(this.resp[this.frequency]["amount"]);
        });
      }
      if (this.financeModal.calcFinanceAmount) {
        this.financeModal.calcFinanceAmount.forEach((input) => {
          input.value = this.amount - this.resp["deposit"];
        });
      }

      if (this.vehicleInfo.price) {
        this.vehicleInfo.price.forEach((input) => {
          input.innerHTML = this.money(this.resp[this.frequency]["amount"]);
        });
      }
      if (this.vehicleInfo.frequency) {
        this.vehicleInfo.frequency.forEach((input) => {
          input.innerHTML = this.frequency + "*";
        });
      }
    },
  },
  filters: {
    money(val) {
      return val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    },
  },
  mounted() {
    // console.log(this.price,this.amount,this.was);

    if (this.price) {
      this.afterInput = true;
      this.amount = this.price;
    }

    this.getData();
    // this.getData().then(() => {
    //   if (this.$refs['input']) {
    //     this.$refs['input'].focus()
    //   }
    // })

    this.notModal = !this.isModal;

    const barbaContainer = this.$root.$options.el.closest(".barba-container");
    const financeModal = select(".finance-modal", barbaContainer);
    const vehicleInfo = select(".vehicle-info", barbaContainer);

    var otherCalcParent = financeModal;
    if (this.notModal) {
      otherCalcParent = vehicleInfo;
    }

    // const appCalculatorEl = select('.app-calculator', otherCalcParent)
    // console.log(appCalculatorEl)
    // if ( appCalculatorEl.length > 0 ) {
    //   this.otherCalc = appCalculatorEl;
    // });

    if (vehicleInfo) {
      this.vehicleInfo.price = select.all(
        ".vehicle-finance-price span.price",
        vehicleInfo
      );
      this.vehicleInfo.frequency = select.all(
        ".vehicle-finance-price span.frequency",
        vehicleInfo
      );
    }

    this.financeModal.tileFinancePrice = select.all(".finance-price div");
    this.financeModal.tileFinanceFreq = select.all(".finance-price span");
    this.financeModal.grayText = select.all(".modal-calculator-text");
    this.financeModal.calcTerm = select.all('input[name="fields[calcTerm]"]');
    this.financeModal.calcDeposit = select.all(
      'input[name="fields[calcDeposit]"]'
    );
    this.financeModal.calcFrequency = select.all(
      'input[name="fields[calcFrequency]"]'
    );
    this.financeModal.calcAmountPerFrequency = select.all(
      'input[name="fields[calcAmountPerFrequency]"]'
    );
    this.financeModal.calcFinanceAmount = select.all(
      'input[name="fields[financeAmount]"]'
    );

    var eventOn = "updatePageModal";
    if (!this.isModal) {
      eventOn = "updatePage";
    }

    //so we can update the other calcs - same calculations on all calcs.
    EventBus.$on(eventOn, (data) => {
      this.resp = data.data;
      this.deposit = data.data.deposit;
      this.term = data.data.term / 12;
      this.frequency = data.frequency;
    });
  },
};
</script>
